<template>
<div class="user">
    <el-row>
        <el-button type="primary" @click='addUserForm'>新增</el-button>
    </el-row>
    <el-row>
        <el-table :data="training_List" style="width: 100%" border>
            <el-table-column prop="username" label="用户名" min-width="100">
            </el-table-column>
            <el-table-column prop="cellphone" label="电话" min-width="100">
            </el-table-column>
            <el-table-column prop="role.name" label="角色" min-width="100">
            </el-table-column>
            <el-table-column label="操作" min-width="100">
                <template #default="scope">
                    <el-button type="primary" icon="el-icon-edit" circle @click="editUserForm(scope.row)"></el-button>
                    <el-button type="danger" icon="el-icon-delete" circle @click="deleteUser(scope.row.uuid)"></el-button>
                </template>
            </el-table-column>
        </el-table>
    </el-row>
    <el-row>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size=pageSize
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalCount">
        </el-pagination>
    </el-row>
    <el-dialog title="用户信息" v-model="dialogVisible" width="45%" center custom-class="form-dialog">
        <el-form :model="form" :rules="rules" ref="form" :label-width="formLabelWidth">
            <el-form-item label="uuid" v-show="false">
                <el-input v-model="form.uuid" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="用户名" prop="username">
                <el-input v-model="form.username" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
                <el-input v-model="form.password" show-password autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="电话" prop="cellphone">
                <el-input v-model="form.cellphone" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="邮箱" prop="email">
                <el-input v-model="form.email" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="角色" prop="role_id">
                <el-select v-model="form.role_id" placeholder="请选择角色">
                    <el-option
                        v-for="item in roleList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </template>
    </el-dialog>
</div>
</template>
<script>
import { userList, roleList, addUser, delUser } from '../../api/user/index'
import { showConfirmMessageBox, showMessage } from '../../api/base/message'
export default {
  name: 'UserList',
  data () {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号不能为空'))
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的手机号'))
        }
      }
    }
    return {
      input: '',
      userList: [],
      roleList: [],
      dialogVisible: false,
      formLabelWidth: '120px',
      currentPage: 1,
      totalCount: 0,
      pageSize: 10,
      form: {
        uuid: '',
        username: '',
        password: '',
        cellphone: '',
        email: '',
        role_id: ''
      },
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 1, max: 32, message: '长度在 1 到 32 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 32, message: '长度在 6 到 32 个字符', trigger: 'blur' }
        ],
        cellphone: [
          { validator: checkPhone, required: true, trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请输入邮箱地址', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur'] }
        ],
        role_id: [
          { required: true, message: '请选择角色', trigger: 'change' }
        ],
      }
    }
  },
  mounted: function () {
    this.showUsers()
    this.showRoles()
  },
  methods: {
    showUsers () {
      var data = {
        offset: (this.currentPage - 1) * this.pageSize,
        limit: this.pageSize
      }
      userList(data)
        .then((res) => {
          if (res.result === 0) {
            this.userList = res.user_list
            this.totalCount = res.total
          } else {
            showMessage('查询用户列表失败', 'error')
            console.log(res.msg)
          }
        })
    },
    showRoles () {
      roleList()
        .then((res) => {
          if (res.result === 0) {
            this.roleList = res.role_list
          } else {
            this.$message.error('查询角色列表失败')
            console.log(res.msg)
          }
        })
    },
    addUserForm () {
      this.dialogVisible = true
      this.form = {
        uuid: '',
        username: '',
        password: '',
        cellphone: '',
        email: '',
        role_id: ''
      }
    },
    editUserForm (row) {
      this.dialogVisible = true
      this.form = {
        uuid: row.uuid,
        username: row.username,
        password: row.password,
        cellphone: row.cellphone,
        email: row.email,
        role_id: row.role.id
      }
    },
    submit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          addUser(this.form)
            .then((res) => {
              if (res.result === 0) {
                this.dialogVisible = false
                this.showUsers()
              } else {
                this.$message.error('保存失败')
              }
            }).catch(() => {})
        } else {
          return false
        }
      })
    },
    deleteUser (delUuid) {
      showConfirmMessageBox('确定要删除该用户吗？')
        .then(() => {
          delUser({ uuid: delUuid })
            .then((res) => {
              if (res.result === 0) {
                this.showUsers()
              }
            })
        })
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
      this.currentPage = 1
      this.pageSize = val
      this.showUsers()
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.currentPage = val
      this.showUsers()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  h1, h2 {
    font-weight: normal;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 0 10px;
  }

  a {
    color: #42b983;
  }

  .el-row {
    padding: 10px 100px;
  }
</style>
