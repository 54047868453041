<template>
  <div class="home">
    <UserList/>
  </div>
  <el-backtop :visibility-height="10"></el-backtop>
</template>
<script>
// @ is an alias to /src
import UserList from '@/components/User/UserList.vue'

export default {
  name: 'User',
  components: {
    UserList
  }
}
</script>
